import { useState } from 'react';
import { RegisterImplementationProps } from 'constants/types';
import SignInBase from '../SignInBase';
import MainContainer from '../SignInMainContainer';
import LoaderButton from 'components/Button/LoaderButton';
import { SwitchImplementationButton } from 'components/LinkButton/LinkButton';
import { SubdomainInput } from 'components/Input/SubdomainInput';
import { AuthorizeContextValue } from 'pages/Authorize/AuthorizeContext';
import { getProviderFormLabel, validateSubdomain } from '../utils';
import { withAuthorizeContext } from 'pages/Authorize/withAuthorizeContext';
import { useOAuthStatePolling } from 'pages/Authorize/OAuth/useOAuthStatePolling';
import { AnalyticsEventName, track } from 'utils/analytics';
const {
  protocol
} = window.location;
const BAMBOOHR_CLIENT_ID = protocol === 'https:' ? 'gh_auto_gen-0b7a2a47db90886a518a0eae3509c73c00b5ebea-06_07_2022_15:45:55' // prod client creds
: 'gh_auto_gen-464b3cf7a1c35de268f2f6abbd1f5cf7017f7e20-08_19_2022_07:32:11'; // dev client creds
const BAMBOOHR_REDIRECT_URI = `${window.location.protocol}//${window.location.host}/auth/bamboo_hr`;
const getOAuthUrl = ({
  subdomain,
  sessionKey
}: {
  subdomain: string;
  sessionKey: string;
}) => {
  const url = new URL(`https://${subdomain}.bamboohr.com/authorize.php`);

  // set all the params that are required for sso
  url.searchParams.set('request', 'authorize');
  url.searchParams.set('state', JSON.stringify({
    sessionKey
  }));
  url.searchParams.set('response_type', 'code');
  url.searchParams.set('scope', 'openid email');
  url.searchParams.set('client_id', BAMBOOHR_CLIENT_ID.toString());
  url.searchParams.set('redirect_uri', BAMBOOHR_REDIRECT_URI);
  return url.toString();
};
export const RegisterBambooHrOAuth = ({
  handleClick,
  onSubmit,
  onMockOAuth,
  setError,
  sessionKey,
  provider
}: RegisterImplementationProps & AuthorizeContextValue) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [subdomain, setSubdomain] = useState('');
  const onCodeReceived = async (clientCode: string) => {
    await onSubmit({
      subdomain,
      clientCode,
      providerRedirectUri: BAMBOOHR_REDIRECT_URI
    });
  };
  const {
    loading,
    openOAuthWindow
  } = useOAuthStatePolling({
    sessionKey,
    onCodeReceived,
    setError
  });
  const onOAuthClick = async (fieldToValueMap: Record<string, string>) => {
    setButtonLoading(true);
    const didMockOAuth = await onMockOAuth();
    if (didMockOAuth) {
      setButtonLoading(false);
      return;
    }
    const {
      subdomain: subdomainValue
    } = fieldToValueMap;
    if (!validateSubdomain(subdomainValue)) {
      setError({
        message: 'Invalid subdomain. Subdomain should contain no spaces, symbols, or http prefixes',
        omitSessionKey: true,
        status: null
      });
      setButtonLoading(false);
      return;
    }
    setSubdomain(subdomainValue);
    const oauthUrl = getOAuthUrl({
      subdomain: subdomainValue,
      sessionKey
    });
    track(AnalyticsEventName.RedirectedToOAuthUrl, {
      oauthUrl
    });
    openOAuthWindow(oauthUrl);
    setButtonLoading(false);
  };
  const instructions: JSX.Element = <p>
      BambooHR authentication will continue in a new window. Please make sure to
      allow popups and finish authenticating through BambooHR in the new window.
    </p>;
  return <SignInBase formLabel={getProviderFormLabel(provider)} instructions={instructions} onSubmit={onOAuthClick} fieldNames={['subdomain']} render={({
    subdomain
  }) => {
    return <>
            <MainContainer useNewDesign>
              <SubdomainInput label="Subdomain" value={subdomain.value} onChange={event => subdomain.onChange(event.target.value)} invalid={subdomain.invalid} domain="bamboohr.com" />
            </MainContainer>
            {handleClick && <SwitchImplementationButton onClick={handleClick} useNewDesign>
                Use a Username and Password instead
              </SwitchImplementationButton>}
            <LoaderButton isLoading={buttonLoading || loading} disabled={buttonLoading || loading || !subdomain.value} type="submit" text="Connect" />
          </>;
  }} data-sentry-element="SignInBase" data-sentry-component="RegisterBambooHrOAuth" data-sentry-source-file="SignInBambooHrOAuth.tsx" />;
};
export const SignInBambooHrOAuth = withAuthorizeContext(RegisterBambooHrOAuth);