import { useEffect, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { authorize } from 'services/auth';
import { withAuthorizeContext, AuthorizeContextValue } from '../AuthorizeContext';
import MainContainer from 'components/Container/MainContainer';
import LoaderButton from 'components/Button/LoaderButton';
import { getStatusCode } from '../SignIn/utils';
import { AuthProcessingResponse } from '@finch-api/common/dist/internal/connect/authorize';
import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import { ProviderAppShell } from '../ProviderAppShell';
import { FinchErrorCode } from 'constants/finch-error-code';
export const CenteredDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Captcha = ({
  client,
  prevStep,
  providerImplementation,
  payrollProvider,
  error,
  setError,
  clientId,
  connectionId,
  clientName,
  redirectUri,
  products,
  sessionKey,
  currentBrowserSessionKey,
  state,
  category,
  sandbox,
  appType,
  implementationHasAssistedBenefits,
  handleAuthorizeResponse,
  setProviderToRedirect,
  mfaCode
}: AuthorizeContextValue) => {
  if (!client) throw new Error('no client');
  if (!payrollProvider) throw new Error('no payroll provider');
  if (!providerImplementation) throw new Error('no provider implementation');
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(undefined);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const onContinue = async () => {
    setLoading(true);
    setError(null);
    try {
      const authorizeResponse = (await authorize({
        clientId,
        connectionId,
        clientName,
        redirectUri,
        products,
        mfaCode,
        sessionKey,
        currentBrowserSessionKey,
        state,
        category,
        step: 'CAPTCHA',
        captchaToken: token,
        payrollProviderId: payrollProvider.id,
        providerImplementationId: providerImplementation.id,
        sandbox,
        appType,
        implementationHasAssistedBenefits
      })) as AuthProcessingResponse;
      handleAuthorizeResponse(authorizeResponse);

      // If we are redirecting, we keep the loading state to avoid double submission.
      // Therefore, we only remove the loading state when there is no redirect.
      if (!('redirect' in authorizeResponse)) {
        setLoading(false);
      }
    } catch (err: any) {
      setLoading(false);
      const finchCode = _.get(err, 'response.data.finch_code');
      if ([FinchErrorCode.AccountSetupRequired, FinchErrorCode.NoValidAccounts, FinchErrorCode.SessionExpired, FinchErrorCode.IncorrectPayrollProvider].includes(finchCode)) {
        setDisableSubmit(true);
      }
      if (finchCode === FinchErrorCode.IncorrectPayrollProvider) {
        const correctPayrollProvider = _.get(err, 'response.data.correct_payroll_provider');
        setProviderToRedirect(correctPayrollProvider);
      }
      if (err.message === 'Incorrect MFA code. Max attempts reached. Please try again from the beginning.') {
        setError({
          message: err.message,
          status: getStatusCode(err),
          finchCode
        });
        prevStep();
      }
      setError({
        message: err.message || 'Unexpected error has occurred. Please try again.',
        status: getStatusCode(err),
        finchCode
      });
    }
  };
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://captcha-endpoint.paycomonline.net/v4/ee/1/api.js/1/api.js?render=explicit&amp&amp;assethost=https%3A%2F%2Fcaptcha-assethost.paycomonline.net%2Fv4%2Fee&amp;endpoint=https%3A%2F%2Fcaptcha-endpoint.paycomonline.net%2Fv4%2Fee&amp;imghost=https%3A%2F%2Fcaptcha-imghost.paycomonline.net%2Fv4%2Fee&amp;reportapi=https%3A%2F%2Fcaptcha-reportapi.paycomonline.net%2Fv4%2Fee';
    script.async = true;
    script.setAttribute('data-testid', 'hcaptcha');
    document.body.appendChild(script);
    script.addEventListener('load', () => {
      (window as any).hcaptcha.render('captcha', {
        sitekey: '12281f21-d27e-48b9-9faf-f83d141d11e5',
        callback: (token: any) => {
          setToken(token);
        }
      });
    });
  }, []);
  return <ProviderAppShell error={error} setError={setError} provider={payrollProvider} providerImplementation={providerImplementation} goBack={() => prevStep()} headerText="Complete the captcha to continue" data-sentry-element="ProviderAppShell" data-sentry-component="Captcha" data-sentry-source-file="Captcha.tsx">
      <MultiValueForm formKey={0} formLabel="Captcha Form" onSubmit={onContinue} fieldNames={[]} render={() => <>
            <MainContainer>
              <CenteredDiv>
                <div id="captcha"></div>
              </CenteredDiv>
            </MainContainer>
            <LoaderButton color={payrollProvider.primaryColor} isLoading={loading} disabled={loading || !token || disableSubmit} text="Continue" />
          </>} data-sentry-element="MultiValueForm" data-sentry-source-file="Captcha.tsx" />
    </ProviderAppShell>;
};
export default withAuthorizeContext(Captcha);