import Mfa from 'pages/Authorize/Mfa';
import SelectProvider from 'pages/Authorize/SelectProvider';
import SignIn from 'pages/Authorize/SignIn';
import PermissionList from 'pages/Authorize/Permissions';
import Preamble from 'pages/Authorize/Preamble';
import ChooseAccount from 'pages/Authorize/ChooseAccount';
import ManualSignIn from './ManualSignIn/ManualSignIn';
import AssistedAccountantInstructions from './ManualSignIn/AssistedAccountantInstructions';
import ManualCaptcha from 'pages/Authorize/Captcha';
import SecurityChallenge from 'pages/Authorize/SecurityChallenge';
import ChooseMfaOption from 'pages/Authorize/ChooseMfaOption';
import { SuccessPage } from './SuccessPage';
import AssistedAccountantOptions from './AssistedAccountantOptions/AssistedAccountantOptions';

export const step = {
  PREAMBLE: 'PREAMBLE',
  PERMISSIONS: 'PERMISSIONS',
  SELECT_PROVIDER: 'SELECT_PROVIDER',
  SIGN_IN: 'SIGN_IN',
  SIGN_IN_WITH_PASSWORD: 'SIGN_IN_WITH_PASSWORD',
  CHOOSE_MFA_OPTION: 'CHOOSE_MFA_OPTION',
  MFA: 'MFA',
  CHOOSE_ACCOUNT: 'CHOOSE_ACCOUNT',
  MANUAL_SIGN_IN: 'MANUAL_SIGN_IN',
  ASSISTED_BENEFITS: 'ASSISTED_BENEFITS',
  ASSISTED_ACCOUNTANT_INSTRUCTIONS: 'ASSISTED_ACCOUNTANT_INSTRUCTIONS',
  ASSISTED_ACCOUNTANT_OPTIONS: 'ASSISTED_ACCOUNTANT_OPTIONS',
  CAPTCHA: 'CAPTCHA',
  SECURITY_CHALLENGE: 'SECURITY_CHALLENGE',
  ADD_FINCH_ACCOUNTANT: 'ADD_FINCH_ACCOUNTANT',
  SESSION_COMPLETE: 'SESSION_COMPLETE',
};

export const AutomatedAuthSteps = [
  step.SIGN_IN,
  step.CHOOSE_MFA_OPTION,
  step.MFA,
  step.CHOOSE_ACCOUNT,
  step.CAPTCHA,
  step.SECURITY_CHALLENGE,
];

export const stepMap = {
  [step.PREAMBLE]: Preamble,
  [step.PERMISSIONS]: PermissionList,
  [step.SELECT_PROVIDER]: SelectProvider,
  [step.SIGN_IN]: SignIn,
  [step.MFA]: Mfa,
  [step.CHOOSE_ACCOUNT]: ChooseAccount,
  [step.MANUAL_SIGN_IN]: ManualSignIn,
  [step.ASSISTED_BENEFITS]: ManualSignIn,
  [step.ASSISTED_ACCOUNTANT_INSTRUCTIONS]: AssistedAccountantInstructions,
  [step.ASSISTED_ACCOUNTANT_OPTIONS]: AssistedAccountantOptions,
  [step.CAPTCHA]: ManualCaptcha,
  [step.CHOOSE_MFA_OPTION]: ChooseMfaOption,
  [step.SECURITY_CHALLENGE]: SecurityChallenge,
  [step.SESSION_COMPLETE]: SuccessPage,
};

export const nextStepMap = {
  [step.PREAMBLE]: step.PERMISSIONS,
  [step.PERMISSIONS]: step.SELECT_PROVIDER,
  [step.SELECT_PROVIDER]: step.SIGN_IN,
  [step.SIGN_IN]: step.MFA,
  [step.MFA]: step.CHOOSE_ACCOUNT,
  [step.CHOOSE_MFA_OPTION]: step.MFA,
  [step.SECURITY_CHALLENGE]: step.ADD_FINCH_ACCOUNTANT,
};

export const nextStepMapReAuth = {
  [step.PREAMBLE]: step.PERMISSIONS,
  [step.PERMISSIONS]: step.SIGN_IN,
  [step.SIGN_IN]: step.MFA,
  [step.MFA]: step.CHOOSE_ACCOUNT,
  [step.CHOOSE_MFA_OPTION]: step.MFA,
  [step.SECURITY_CHALLENGE]: step.ADD_FINCH_ACCOUNTANT,
};

export const prevStepMap = {
  [step.PERMISSIONS]: step.PREAMBLE,
  [step.SELECT_PROVIDER]: step.PERMISSIONS,
  [step.SIGN_IN]: step.SELECT_PROVIDER,
  [step.MANUAL_SIGN_IN]: step.SELECT_PROVIDER,
  [step.MFA]: step.SIGN_IN,
  [step.SECURITY_CHALLENGE]: step.SIGN_IN,
  [step.CAPTCHA]: step.SIGN_IN,
  [step.CHOOSE_ACCOUNT]: step.SIGN_IN,
  [step.CHOOSE_MFA_OPTION]: step.SIGN_IN,
};

export const prevStepMapReAuth = {
  [step.PERMISSIONS]: step.PREAMBLE,
  [step.SIGN_IN]: step.PERMISSIONS,
  [step.MANUAL_SIGN_IN]: step.PERMISSIONS,
  [step.MFA]: step.SIGN_IN,
  [step.SECURITY_CHALLENGE]: step.SIGN_IN,
  [step.CAPTCHA]: step.SIGN_IN,
  [step.CHOOSE_ACCOUNT]: step.SIGN_IN,
  [step.CHOOSE_MFA_OPTION]: step.SIGN_IN,
};
