import { BiShow, BiHide } from 'react-icons/bi';
import Input, { InputProps } from './Input';
import { ResetPasswordOnProviderLink } from 'components/Link/ResetPasswordOnProviderLink';
import COLORS from 'constants/colors';
import { useState } from 'react';
type PasswordResetProps = {
  reset?: {
    uri: URL;
    providerDisplayName: string;
  };
};
const icon: {
  style: React.CSSProperties;
  size: string;
  color: string;
} = {
  style: {
    position: 'absolute',
    top: '17px',
    right: '10px',
    cursor: 'pointer'
  },
  size: '22px',
  color: COLORS.GRAY.GRAY_600
};
const PasswordInput = ({
  reset,
  id = 'password',
  label = 'Password',
  type,
  ...props
}: InputProps & PasswordResetProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  const toggleVisible = () => {
    setVisible(!visible);
  };
  return <div style={{
    position: 'relative'
  }} data-sentry-component="PasswordInput" data-sentry-source-file="PasswordInput.tsx">
      <Input type={visible ? 'text' : 'password'} id={id} label={label} {...props} data-sentry-element="Input" data-sentry-source-file="PasswordInput.tsx" />
      <div>
        {visible ? <BiHide onClick={() => toggleVisible()} color={icon.color} size={icon.size} style={icon.style} /> : <BiShow onClick={() => toggleVisible()} color={icon.color} size={icon.size} style={icon.style} />}
      </div>
      {reset && <ResetPasswordOnProviderLink href={reset.uri.toString()} provider={reset.providerDisplayName} />}
    </div>;
};
PasswordInput.defaultProps = {
  id: 'password',
  label: 'Password'
};
export default PasswordInput;