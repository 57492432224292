import {
  ImplementationKind,
  PlainImplementationKind,
  toPlainImplementationKindMap,
} from '@finch-api/common/dist/internal/connect/authorize';
import {
  PayrollProviderId,
  RegisterImplementationProps,
} from 'constants/types';
import RegisterGustoCredential from './Gusto/RegisterGustoCredential';
import RegisterRipplingApi from './Rippling/RegisterRipplingApi';
import SignInGusto from './Gusto/SignInGusto';
import SignInPaylocityApi from './Paylocity/SignInPaylocityApi';
import SignInPaylocityCredential from './Paylocity/SignInPaylocityCredential';
import SignInJustworksCredential from './Justworks/SignInJustworksCredential';
import SignInSquareOAuth from './SquarePayroll/SignInSquareOAuth';
import SignInSquareCredential from './SquarePayroll/SignInSquareCredential';
import SignInSaplingApi from './Sapling/SignInSaplingApi';
import SignInSageHRApi from './SageHR/SignInSageHRApi';
import SignInSequoiaOneCredential from './SequoiaOne/SignInSequoiaOneCredential';
import SignInPaycomCredential from './Paycom/SignInPaycomCredential';
import SignInPaycomApi from './Paycom/SignInPaycomApi';
import { SignInAdpRunCredential } from './ADPRun/SignInAdpRunCredential';
import { SignInAdpWorkforceNowCredential } from './ADPWorkforceNow/SignInAdpWorkforceNowCredential';
import SignInWaveCredential from './Wave/SignInWaveCredential';
import SignInQuickbooksCredential from './Quickbooks/SignInQuickbooksCredential';
import SignInZenefitsCredential from './Zenefits/SignInZenefitsCredential';
import SignInUltiProApi from './UltiPro/SignInUltiProApi';
import SignInUKGReadyApi from './UKGReady/SignInUKGReadyApi';
import SignInTriNetApi from './TriNet/SignInTriNetApi';
import SignInWorkdayCredential from './Workday/SignInWorkdayCredential';
import SignInWorkdayApi from './Workday/SignInWorkdayApi';
import { SignInAlphaStaffApi } from './AlphaStaff/SignInAlphaStaffApi';
import { SignInBambooHrCredential } from './BambooHR/SignInBambooHrCredential';
import { SignInBambooHrOAuth } from './BambooHR/SignInBambooHrOAuth';
import { SignInBobApi } from './Bob/SignInBobApi';
import { SignInBobCredential } from './Bob/SignInBobCredential';
import SignInHumaansApi from './Humaans/SignInHumaansApi';
import SignInHorizonPayrollCredential from './HorizonPayroll/SignInHorizonPayrollCredential';
import SignInNamelyCredential from './Namely/SignInNamelyCredential';
import SignInNamelyApi from './Namely/SignInNamelyApi';
import SignInInsperityCredential from './Insperity/SignInInsperityCredential';
import SignInInsperityApi from './Insperity/SignInInsperityAPI';
import SignInIsolvedApi from './ISolved/SignInIsolvedApi';
import SignInPaychexFlexCredential from './PaychexFlex/SignInPaychexFlexCredential';
import SignInPaychexFlexApi from './PaychexFlex/SignInPaychexFlexApi';
import SignInPaycorOAuth from './Paycor/SignInPaycorOAuth';
import SignInCeridianDayforceApi from './CeridianDayforce/SignInCeridianDayforceApi';
import SignInPersonioApi from './Personio/SignInPersonioApi';
import SignInOysterHrOAuth from './OysterHR/SignInOysterHrOAuth';
import SignInAppliedBusinessSolutionsApi from './AppliedBusinessSolutions/SignInAppliedBusinessSolutionsApi';
import SignInHybridApi from './Hybrid/SignInHybridApi';
import SignInFinchCredential from './Finch/SignInFinchCredential';
import SignInFactorialHROAuth from './FactorialHR/SignInFactorialHROAuth';
import SignInDeelOAuth from './Deel/SignInDeelOAuth';
import SignInCounterpointHcmApi from './CounterpointHcm/SignInCounterpointHcmApi';
import SignInRipplingOAuth from './Rippling/SignInRipplingOAuth';
import SignInOnpayCredential from './Onpay/SignInOnpayCredential';
import SignInZenefitsApi from './Zenefits/SignInZenefitsApi';
import SignInJustworksOAuth from './Justworks/SignInJustworksOAuth';

// TEMP: used to store new design screens until the feature flag is removed
const providerToComponentMap: {
  [k in PayrollProviderId]: Partial<{
    [k in PlainImplementationKind]:
      | ((props: RegisterImplementationProps) => JSX.Element)
      | null;
  }>;
} = {
  [PayrollProviderId.ADP_RUN]: {
    [ImplementationKind.CREDENTIAL]: SignInAdpRunCredential,
  },
  [PayrollProviderId.ADP_WORKFORCE_NOW]: {
    [ImplementationKind.CREDENTIAL]: SignInAdpWorkforceNowCredential,
  },
  [PayrollProviderId.ALPHASTAFF]: {
    [ImplementationKind.API_TOKEN]: SignInAlphaStaffApi,
  },
  [PayrollProviderId.APPLIED_BUSINESS_SOLUTIONS]: {
    [ImplementationKind.API_TOKEN]: SignInAppliedBusinessSolutionsApi,
  },
  [PayrollProviderId.BAMBOO_HR]: {
    [ImplementationKind.CREDENTIAL]: SignInBambooHrCredential,
    [ImplementationKind.API_TOKEN]: SignInBambooHrOAuth,
    [ImplementationKind.OAUTH]: SignInBambooHrOAuth,
  },
  [PayrollProviderId.BOB]: {
    [ImplementationKind.API_TOKEN]: SignInBobApi,
    [ImplementationKind.CREDENTIAL]: SignInBobCredential,
  },
  [PayrollProviderId.CERIDIAN_DAYFORCE]: {
    [ImplementationKind.API_TOKEN]: SignInCeridianDayforceApi,
  },
  [PayrollProviderId.COUNTERPOINT_HCM]: {
    [ImplementationKind.API_TOKEN]: SignInCounterpointHcmApi,
  },
  [PayrollProviderId.DEEL]: {
    [ImplementationKind.API_TOKEN]: SignInDeelOAuth,
    [ImplementationKind.OAUTH]: SignInDeelOAuth,
  },
  [PayrollProviderId.FACTORIAL_HR]: {
    [ImplementationKind.OAUTH]: SignInFactorialHROAuth,
  },
  [PayrollProviderId.FINCH]: {
    [ImplementationKind.CREDENTIAL]: SignInFinchCredential,
  },
  [PayrollProviderId.GUSTO]: {
    [ImplementationKind.CREDENTIAL]: RegisterGustoCredential,
    [ImplementationKind.API_TOKEN]: null,
    [ImplementationKind.OAUTH]: SignInGusto,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.HORIZON_PAYROLL]: {
    [ImplementationKind.CREDENTIAL]: SignInHorizonPayrollCredential,
  },
  [PayrollProviderId.HUMAANS]: {
    [ImplementationKind.API_TOKEN]: SignInHumaansApi,
  },
  [PayrollProviderId.HYBRID]: {
    [ImplementationKind.API_TOKEN]: SignInHybridApi,
  },
  [PayrollProviderId.INSPERITY]: {
    [ImplementationKind.CREDENTIAL]: SignInInsperityCredential,
    [ImplementationKind.API_TOKEN]: SignInInsperityApi,
    [ImplementationKind.OAUTH]: SignInInsperityApi,
  },
  [PayrollProviderId.ISOLVED]: {
    [ImplementationKind.API_TOKEN]: SignInIsolvedApi,
  },
  [PayrollProviderId.JUSTWORKS]: {
    [ImplementationKind.CREDENTIAL]: SignInJustworksCredential,
    [ImplementationKind.API_TOKEN]: null,
    [ImplementationKind.OAUTH]: SignInJustworksOAuth,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.NAMELY]: {
    [ImplementationKind.CREDENTIAL]: SignInNamelyCredential,
    [ImplementationKind.API_TOKEN]: SignInNamelyApi,
    [ImplementationKind.OAUTH]: SignInNamelyApi,
  },
  [PayrollProviderId.ONPAY]: {
    [ImplementationKind.CREDENTIAL]: SignInOnpayCredential,
    [ImplementationKind.API_TOKEN]: null,
    [ImplementationKind.OAUTH]: null,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.OYSTER_HR]: {
    [ImplementationKind.OAUTH]: SignInOysterHrOAuth,
  },
  [PayrollProviderId.PAYCHEX_FLEX]: {
    [ImplementationKind.CREDENTIAL]: SignInPaychexFlexCredential,
    [ImplementationKind.API_TOKEN]: SignInPaychexFlexApi,
    [ImplementationKind.OAUTH]: SignInPaychexFlexApi,
  },
  [PayrollProviderId.PAYCOM]: {
    [ImplementationKind.CREDENTIAL]: SignInPaycomCredential,
    [ImplementationKind.API_TOKEN]: SignInPaycomApi,
    [ImplementationKind.OAUTH]: SignInPaycomApi,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.PAYCOR]: {
    [ImplementationKind.API_TOKEN]: SignInPaycorOAuth,
    [ImplementationKind.OAUTH]: SignInPaycorOAuth,
  },
  [PayrollProviderId.PAYLOCITY]: {
    [ImplementationKind.CREDENTIAL]: SignInPaylocityCredential,
    [ImplementationKind.API_TOKEN]: SignInPaylocityApi,
    [ImplementationKind.OAUTH]: SignInPaylocityApi,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.PERSONIO]: {
    [ImplementationKind.API_TOKEN]: SignInPersonioApi,
    [ImplementationKind.OAUTH]: SignInPersonioApi,
  },
  [PayrollProviderId.QUICKBOOKS]: {
    [ImplementationKind.CREDENTIAL]: SignInQuickbooksCredential,
  },
  [PayrollProviderId.RIPPLING]: {
    [ImplementationKind.OAUTH]: SignInRipplingOAuth,
    [ImplementationKind.API_TOKEN]: RegisterRipplingApi,
  },
  [PayrollProviderId.SAGE_HR]: {
    [ImplementationKind.CREDENTIAL]: null,
    [ImplementationKind.API_TOKEN]: SignInSageHRApi,
    [ImplementationKind.OAUTH]: SignInSageHRApi,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.SAPLING]: {
    [ImplementationKind.CREDENTIAL]: null,
    [ImplementationKind.API_TOKEN]: SignInSaplingApi,
    [ImplementationKind.OAUTH]: SignInSaplingApi,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.SEQUOIA_ONE]: {
    [ImplementationKind.CREDENTIAL]: SignInSequoiaOneCredential,
    [ImplementationKind.API_TOKEN]: null,
    [ImplementationKind.OAUTH]: null,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.SQUARE_PAYROLL]: {
    [ImplementationKind.CREDENTIAL]: SignInSquareCredential,
    [ImplementationKind.API_TOKEN]: SignInSquareOAuth,
    [ImplementationKind.OAUTH]: SignInSquareOAuth,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.TRINET]: {
    [ImplementationKind.CREDENTIAL]: null,
    [ImplementationKind.API_TOKEN]: SignInTriNetApi,
    [ImplementationKind.OAUTH]: SignInTriNetApi,
    [ImplementationKind.ASSISTED]: null,
  },
  [PayrollProviderId.UKG_READY]: {
    [ImplementationKind.API_TOKEN]: SignInUKGReadyApi,
  },
  [PayrollProviderId.ULTI_PRO]: {
    [ImplementationKind.API_TOKEN]: SignInUltiProApi,
    [ImplementationKind.OAUTH]: SignInUltiProApi,
  },
  [PayrollProviderId.WAVE]: {
    [ImplementationKind.CREDENTIAL]: SignInWaveCredential,
  },
  [PayrollProviderId.WORKDAY]: {
    [ImplementationKind.CREDENTIAL]: SignInWorkdayCredential,
    [ImplementationKind.API_TOKEN]: SignInWorkdayApi,
  },
  [PayrollProviderId.ZENEFITS]: {
    [ImplementationKind.CREDENTIAL]: SignInZenefitsCredential,
    [ImplementationKind.API_TOKEN]: SignInZenefitsApi,
  },
};

export const getProviderComponent = (
  payrollProviderId: PayrollProviderId,
  implementationKind: ImplementationKind,
) => {
  const rawImplementationKind =
    toPlainImplementationKindMap[implementationKind];

  return providerToComponentMap[payrollProviderId]?.[rawImplementationKind];
};
