import styled from 'styled-components';
import invalidCircleIcon from 'assets/img/invalidCircle.svg';
import { ContentContainer, SandboxContainer, TextGroup, TitleText } from '../Errors/styles';
import { AuthorizeContextValue, withAuthorizeContext } from 'pages/Authorize/AuthorizeContext';
import COLORS from 'constants/colors';
import { authorize } from 'services/auth';
import LoaderButton from 'components/Button/LoaderButton';
import { Container } from '@chakra-ui/react';
import LoadingSpinner from '../LoadingSpinner';
import { getStatusCode } from '../SignIn/utils';
import { useState } from 'react';
import { step } from '../steps';
const Paragraph = styled.p`
  text-align: left;
  margin-bottom: 10px;
  font-size: 14px;
  letter-spacing: -0.1px;
`;
export const StyledRadio = styled.div`
  color: ${COLORS.GRAY.GRAY_900};
  display: flex;
  align-items: center;
  position: relative;
`;
export const StyledInput = styled.input`
  height: 18px;
  width: 18px;
  margin: 0px;
  appearance: none;

  position: relative;
  background-color: ${COLORS.WHITE};
  border: solid 1px ${COLORS.GRAY.GRAY_400};
  border-radius: 20px;
  cursor: pointer;
  padding: 1px;

  &:checked:after {
    content: '';
    background-color: ${props => props.color};
    border: solid 5px ${props => props.color};
    border-radius: 20px;
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
`;
export const StyledLabel = styled.label`
  display: inline-block;
  position: relative;
  padding-left: 8px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 500;
`;
export const TagLabel = styled.span`
  font-weight: 500;
  font-size: 12px;
  padding: 4px 5px 4px 6px;
  border-radius: 6px;
  background-color: ${COLORS.BLUE.BLUE_50};
  color: ${COLORS.BLUE.BLUE_300};
`;
const OptionDescriptionParagraph = styled.p`
  text-align: left;
  margin-bottom: 10px;
  margin-left: 27px;
  font-size: 14px;
`;
const AssistedAccountantOptions = ({
  client,
  payrollProvider,
  providersConfig,
  providerImplementation,
  handleAuthorizeResponse,
  products,
  setError,
  clientName,
  connectionId,
  clientId,
  redirectUri,
  sessionKey,
  currentBrowserSessionKey,
  state,
  category,
  sandbox,
  appType,
  implementationHasAssistedBenefits,
  setCurrentStep
}: AuthorizeContextValue) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [option, setOption] = useState<string>('MANUAL_SETUP');
  if (!client) return <Container>{!client && <LoadingSpinner />}</Container>;
  if (!payrollProvider) throw new Error('no payroll provider');
  if (!providersConfig) throw new Error('no providers config');
  if (!providerImplementation) throw new Error('no provider implementation');
  if (!products) throw new Error('no products');
  const onChangeOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOption(event.target.value);
  };
  const onSubmit = async () => {
    setIsLoading(true);
    setError(null);
    if (option === 'MANUAL_SETUP') {
      setCurrentStep(step.ASSISTED_ACCOUNTANT_INSTRUCTIONS);
      setIsLoading(false);
      return;
    }
    try {
      const authorizeResponse = await authorize({
        clientId,
        connectionId,
        clientName,
        redirectUri,
        products,
        sessionKey,
        currentBrowserSessionKey,
        state,
        category,
        step: step.ASSISTED_ACCOUNTANT_INSTRUCTIONS,
        payrollProviderId: payrollProvider.id,
        providerImplementationId: providerImplementation.id,
        sandbox,
        appType,
        implementationHasAssistedBenefits
      });
      handleAuthorizeResponse(authorizeResponse);
    } catch (err: any) {
      setError({
        message: err.message || 'Unexpected error has occurred. Please try again.',
        status: getStatusCode(err),
        finchCode: err.response?.data?.finch_code
      });
    } finally {
      setIsLoading(false);
    }
  };
  return <SandboxContainer data-sentry-element="SandboxContainer" data-sentry-component="AssistedAccountantOptions" data-sentry-source-file="AssistedAccountantOptions.tsx">
      <ContentContainer data-sentry-element="ContentContainer" data-sentry-source-file="AssistedAccountantOptions.tsx">
        <img alt="invalid-circle" src={invalidCircleIcon} />
        <TextGroup data-sentry-element="TextGroup" data-sentry-source-file="AssistedAccountantOptions.tsx">
          <TitleText data-sentry-element="TitleText" data-sentry-source-file="AssistedAccountantOptions.tsx">Something went wrong</TitleText>
          <Paragraph data-sentry-element="Paragraph" data-sentry-source-file="AssistedAccountantOptions.tsx">
            We’re having trouble connecting to {payrollProvider?.displayName}.
            You may have to connect manually.
          </Paragraph>
          <Paragraph data-sentry-element="Paragraph" data-sentry-source-file="AssistedAccountantOptions.tsx">Please choose an option to continue:</Paragraph>
          <StyledRadio data-sentry-element="StyledRadio" data-sentry-source-file="AssistedAccountantOptions.tsx">
            <StyledInput id="manually" type="radio" name="continue" value="MANUAL_SETUP" onChange={onChangeOption} checked={option === 'MANUAL_SETUP'} data-sentry-element="StyledInput" data-sentry-source-file="AssistedAccountantOptions.tsx" />
            <StyledLabel htmlFor="manually" data-sentry-element="StyledLabel" data-sentry-source-file="AssistedAccountantOptions.tsx">
              Connect manually <TagLabel data-sentry-element="TagLabel" data-sentry-source-file="AssistedAccountantOptions.tsx">Recommended</TagLabel>
            </StyledLabel>
          </StyledRadio>
          <OptionDescriptionParagraph data-sentry-element="OptionDescriptionParagraph" data-sentry-source-file="AssistedAccountantOptions.tsx">
            We’ll provide you with step-by-step instructions on the next screen.
          </OptionDescriptionParagraph>
          <OptionDescriptionParagraph data-sentry-element="OptionDescriptionParagraph" data-sentry-source-file="AssistedAccountantOptions.tsx">
            After successfully completing the instructions, your connection
            should remain stable without needing to frequently reconnect.
          </OptionDescriptionParagraph>
          <StyledRadio data-sentry-element="StyledRadio" data-sentry-source-file="AssistedAccountantOptions.tsx">
            <StyledInput id="bypass" type="radio" name="continue" value="SKIP" onChange={onChangeOption} checked={option === 'SKIP'} data-sentry-element="StyledInput" data-sentry-source-file="AssistedAccountantOptions.tsx" />
            <StyledLabel htmlFor="bypass" data-sentry-element="StyledLabel" data-sentry-source-file="AssistedAccountantOptions.tsx">Bypass manual connection</StyledLabel>
          </StyledRadio>
          <OptionDescriptionParagraph data-sentry-element="OptionDescriptionParagraph" data-sentry-source-file="AssistedAccountantOptions.tsx">
            Once connected, you may have to reonnect periodically.
          </OptionDescriptionParagraph>
        </TextGroup>
      </ContentContainer>
      <LoaderButton isLoading={isLoading} disabled={isLoading} type="button" text="Continue" onClick={onSubmit} data-sentry-element="LoaderButton" data-sentry-source-file="AssistedAccountantOptions.tsx" />
    </SandboxContainer>;
};
export default withAuthorizeContext(AssistedAccountantOptions);